import React from 'react';
import './Feature.css';
import icons from '../../assets/icons-sprite.svg';

const Feature = (props) => {
  return (
    <div className="feature__container">
      <h3 className="feature__heading">
        <svg>
          <use href={`${icons}#${props.icon}`}></use>
        </svg>
        <span className="feature__title">{props.title}</span>
      </h3>
      <p className="feature__description">{props.description}</p>
    </div>
  );
};

export default Feature;
