import React from 'react';
import './Features.css';
import { Feature } from '../../components';

const Features = () => {
  const features = [
    {
      id: 1,
      title: 'Rewarded discovery',
      description:
        'Acquire new engaged audiences by driving them through deep in-app touchpoints with multi-reward campaigns.',
      icon: 'loop-icon',
    },
    {
      id: 2,
      title: 'Get the users that matter',
      description:
        'Our audience is 100% identity-verified and we support age, gender and interest targeting.',
      icon: 'user-icon',
    },
    {
      id: 3,
      title: 'Deep engagement',
      description:
        'Encourage unveiled audiences to use your app for several weeks to create a habitual and engaging experience with spacious and tiered rewards.',
      icon: 'engagement-icon',
    },
    {
      id: 4,
      title: 'Targeted discovery',
      description:
        'Tailored-selection of apps for each audience segment creates a sense of personalization and native discovery allowing app-brands interact only with targeted audiences',
      icon: 'target-icon',
    },
  ];

  return (
    <section className="container features__container section--blue-bg">
      <h2 className="features__heading">Our platform</h2>
      <div className="features__items">
        {features.map((feature) => (
          <Feature
            key={feature.id}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
          />
        ))}
      </div>
    </section>
  );
};

export default Features;
