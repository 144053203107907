import { logEvent } from '@firebase/analytics';
import { addDoc, collection, serverTimestamp } from '@firebase/firestore';
import React, { useState } from 'react';
import { ContactForm, Modal } from '../../components';
import { analytics, db } from '../../services/firebase';
import './Contact.css';

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleFormSubmit = async (data) => {
    setSaving(true);
    logEvent(analytics, 'form_submit');

    await addDoc(collection(db, 'applications'), {
      ...data,
      created_at: serverTimestamp(),
    });

    setSaving(false);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section
      id="contact"
      className="container contact-us__container section--blue-bg"
    >
      <h2 className="contact-us__heading">Let's get in touch</h2>
      <ContactForm onFormSubmit={handleFormSubmit} isSaving={saving} />
      {showModal && (
        <Modal
          icon="success-icon"
          title="Thank you!"
          message="Your form has been successfully submitted."
          onClose={handleCloseModal}
        />
      )}
    </section>
  );
};

export default Contact;
