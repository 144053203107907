import React from 'react';
import './Header.css';
import logo from '../../assets/logo.png';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <header className="container header__container">
      <a href="/" className="header__logo">
        <img className="header__logo_image" src={logo} alt="logo" />
        <span className="header__logo_text">Scrambly</span>
      </a>
      <Link
        className="header__cta"
        to="contact"
        spy={true}
        smooth={true}
        duration={500}
      >
        Get in touch
      </Link>
    </header>
  );
};

export default Header;
