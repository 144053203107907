import React, { useEffect } from 'react';
import './Modal.css';
import icons from '../../assets/icons-sprite.svg';

const Modal = ({ icon, title, message, onClose }) => {
  useEffect(() => {
    const bodyClassList = document.body.classList;

    bodyClassList.add('noscroll');

    return () => {
      bodyClassList.remove('noscroll');
    };
  }, []);

  return (
    <>
      <div className="overlay" onClick={onClose}></div>
      <div className="modal__container">
        <div className="modal__header">
          <svg>
            <use href={`${icons}#${icon}`}></use>
          </svg>
          <h2>{title}</h2>
        </div>
        <p className="modal__body">{message}</p>
        <button className="modal__close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </>
  );
};

export default Modal;
