import React from 'react';
import './Hero.css';
import phone from '../../assets/phone.png';

const Hero = () => {
  return (
    <section className="hero__container container">
      <div className="hero__info">
        <h1 className="hero__heading">
          Get your app discovered by untapped audiences
        </h1>
        <p className="hero__sub-heading">
          First rewarded ad-platform focused on deep app-engagement
        </p>
      </div>
      <div className="hero__image-wrapper">
        <img className="hero__image" src={phone} alt="hero" />
      </div>
    </section>
  );
};

export default Hero;
