import React from 'react';
import './Partners.css';
import murka from '../../assets/murka-logo.svg';
import playtika from '../../assets/playtika-logo.svg';
import yotta from '../../assets/yotta-logo.svg';
import self from '../../assets/self-logo.svg';
import uphold from '../../assets/uphold-logo.svg';
import albert from '../../assets/albert-logo.svg';
import tellus from '../../assets/tellus-logo.svg';
import binance from '../../assets/binance-logo.svg';

const Partners = () => {
  return (
    <section className="partners__container">
      <h2 className="partners__heading container">Trusted by</h2>
      <div className="logos_slider">
        <div className="logos_wrapper">
          <div className="logo_item">
            <img src={self} alt="Self logo" />
          </div>
          <div className="logo_item">
            <img src={murka} alt="Murka logo" />
          </div>
          <div className="logo_item">
            <img src={playtika} alt="Playtika logo" />
          </div>
          <div className="logo_item">
            <img src={uphold} alt="Uphold logo" />
          </div>
          <div className="logo_item">
            <img src={albert} alt="Albert logo" />
          </div>
          <div className="logo_item">
            <img src={yotta} alt="Yotta logo" />
          </div>
          <div className="logo_item">
            <img src={tellus} alt="Tellus logo" />
          </div>
          <div className="logo_item">
            <img src={binance} alt="Binance logo" />
          </div>
        </div>
        <div className="logos_wrapper">
          <div className="logo_item">
            <img src={self} alt="Self logo" />
          </div>
          <div className="logo_item">
            <img src={murka} alt="Murka logo" />
          </div>
          <div className="logo_item">
            <img src={playtika} alt="Playtika logo" />
          </div>
          <div className="logo_item">
            <img src={uphold} alt="Uphold logo" />
          </div>
          <div className="logo_item">
            <img src={albert} alt="Albert logo" />
          </div>
          <div className="logo_item">
            <img src={yotta} alt="Yotta logo" />
          </div>
          <div className="logo_item">
            <img src={tellus} alt="Tellus logo" />
          </div>
          <div className="logo_item">
            <img src={binance} alt="Binance logo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
