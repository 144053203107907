import React from 'react';
import './ContactForm.css';
import icons from '../../assets/icons-sprite.svg';
import { useForm } from 'react-hook-form';

const ContactForm = ({ onFormSubmit, isSaving }) => {
  const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleFormSubmit = async (data) => {
    await onFormSubmit(data);
    reset();
  };

  return (
    <form className="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-field-group">
        <input
          className="form-field"
          type="text"
          placeholder="Email"
          name="email"
          {...register('email', { required: true, pattern: emailPattern })}
        />
        {errors.email && errors.email.type === 'required' && (
          <p className="form-field__error">Email is required.</p>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <p className="form-field__error">Email is not valid.</p>
        )}
      </div>
      <div className="form-field-group">
        <input
          className="form-field"
          type="text"
          placeholder="Title"
          name="title"
          {...register('title', { required: true, maxLength: 200 })}
        />
        {errors.title && errors.title.type === 'required' && (
          <p className="form-field__error">Title is required.</p>
        )}
        {errors.title && errors.title.type === 'maxLength' && (
          <p className="form-field__error">
            The maximum length is 200 characters.
          </p>
        )}
      </div>

      <div className="form-field-group">
        <textarea
          className="form-field"
          placeholder="Message"
          name="message"
          rows="8"
          {...register('message', { required: true, maxLength: 2000 })}
        ></textarea>
        {errors.message && errors.message.type === 'required' && (
          <p className="form-field__error">Message is required.</p>
        )}
        {errors.message && errors.message.type === 'maxLength' && (
          <p className="form-field__error">
            The maximum length is 2000 characters.
          </p>
        )}
      </div>

      <button className="submit-btn" type="submit" disabled={isSaving}>
        Send
        <svg>
          <use href={`${icons}#submit-icon`}></use>
        </svg>
      </button>
    </form>
  );
};

export default ContactForm;
