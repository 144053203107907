import React from 'react';
import './Footer.css';
import icons from '../../assets/icons-sprite.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="container footer__container">
      <div className="footer__contacts">
        <a href="mailto:advertisers@scrambly.io">advertisers@scrambly.io</a>
        <div className="footer__socials">
          <a
            className="footer__social-item"
            href="https://www.linkedin.com/company/scrambly"
            target="_blank"
            rel="noreferrer"
            aria-label="LinkedIn"
          >
            <svg>
              <use href={`${icons}#linkedin-icon`}></use>
            </svg>
          </a>
          <a
            className="footer__social-item"
            href="https://www.instagram.com/scrambly.io"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <svg>
              <use href={`${icons}#instagram-icon`}></use>
            </svg>
          </a>
          <a
            className="footer__social-item"
            href="https://twitter.com/scrambly_io"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <svg>
              <use href={`${icons}#twitter-icon`}></use>
            </svg>
          </a>
        </div>
      </div>
      <a
        className="footer__platform-btn"
        href="https://scrambly.io/"
        target="_blank"
        rel="noreferrer"
      >
        <svg>
          <use href={`${icons}#link-icon`}></use>
        </svg>
        User Platform
      </a>
      <p>&copy; {currentYear} Scrambly S.r.l. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
