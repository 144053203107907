import './App.css';
import {
  Features,
  Footer,
  Header,
  Hero,
  Partners,
  Contact,
} from './containers';

function App() {
  return (
    <>
      <Header />
      <main className="arrange">
        <Hero />
        <Features />
        <Partners />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
